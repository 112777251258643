export default function safeJsonParse(str) {
  const tries = [str, str?.replace(/\\"/g, '"').replace(/\n/g, '\\n')]

  for (let i = 0; i < tries.length; i++) {
    try {
      return JSON.parse(tries[i])
    } catch (_) {}
  }

  return str
}
