import safeJsonParse from 'utils/safeJsonParse'

if (typeof window === 'undefined') {
  throw new Error('Dont require client-config on the server')
}

const firebaseClient = safeJsonParse(
  process.env.NEXT_PUBLIC_FIREBASE_APP_CONFIG
)

export default {
  firebaseClient
}
