/* eslint-disable global-require */
import config from 'config'
// eslint-disable-next-line import/no-mutable-exports
let firebase

if (typeof window === 'undefined') {
  firebase = require('firebase-admin')
  if (!firebase.apps.find((app) => app.name === '[DEFAULT]')) {
    if (process.env.FIREBASE_CONFIG) {
      firebase.initializeApp()
    } else {
      firebase.initializeApp({
        credential: firebase.credential.cert(config.firebaseAdminCredential),
        databaseURL: config.databaseURL,
        storageBucket: config.storageBucket
      })
    }
  }
} else {
  firebase = require('firebase/app')
  require('firebase/auth')
  require('firebase/firestore')
  require('firebase/analytics')
  require('firebase/storage')
  firebase.initializeApp(config.firebaseClient)
  // https://firebase.google.com/docs/reference/js/firebase.firestore.Firestore#setLogLevel
  // firebase.firestore.setLogLevel('debug')
}

export const serializer = require('firebase-serialize')

export default firebase
