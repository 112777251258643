// eslint-disable-next-line import/no-unresolved
import envConfig from 'env-config'
import logo from 'assets/images/humbaur-logo.svg'
import logoUrl from 'assets/images/humbaur-logo.png?url'
import logoUrlSvg from 'assets/images/humbaur-logo.svg?url'

const muiTheme = {
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#ffde00'
    },
    background: {
      default: '#ffffff'
    }
  },
  drawerWidth: 240
}

const permissions = [
  'createEntity',
  'deleteEntity',
  'editEntity',
  'editFields',
  'editPermissions',
  'viewDrawerItem',
  'editTranslations'
]

const isDev = process.env.NODE_ENV !== 'production'
const domain = process.env.VERCEL_URL || `localhost:${process.env.PORT}`
const baseUrl = isDev ? `http://${domain}` : `https://${domain}`
const projectName = process.env.NEXT_PUBLIC_PROJECT_NAME
const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY // AIzaSyC_0FxCqJHKBckPLxjm7G0bfVgRE3XRE6U
const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN // https://e576a53c301b4cccab2827a994dcd86a@sentry.io/1854032
const sentryEnvironment = process.env.SENTRY_ENVIRONMENT

const logflare = {
  apiKey: process.env.NEXT_PUBLIC_LOGFLARE_INGEST_APIKEY,
  sourceToken: process.env.NEXT_PUBLIC_LOGFLARE_SOURCE_TOKEN
}

export const sharedConfig = {
  shared: true,
  isDev,
  muiTheme,
  logo,
  logoUrl,
  logoUrlSvg,
  projectName,
  googleMapsApiKey,
  domain,
  baseUrl: `https://${domain}`,
  authUrl: 'https://crm.humbaur.babelbing.io',
  sentryDsn,
  buildId: process.env.BUILD_ID,
  sentryEnvironment,
  permissions,
  features: {
    extendedReferenceFilters: false,
    showEmailHistoryOnSendTicketMessage: true
  },
  logflare
}

export default {
  ...sharedConfig,
  ...envConfig
}
